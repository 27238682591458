import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoginService } from "./services/login.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _loginService: LoginService) { //private _authService: AuthService
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        let headers = req.headers.set('Accept', 'application/json');
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        // if (this._authService.accessToken) {
        //     headers = req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken);
        //     newReq = req.clone({
        //         headers: headers
        //     });
        // }

        // Response
        return next.handle(newReq).pipe(
            catchError((err) => {

                // Catch "401 Unauthorized" responses
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    // Sign out
                    this._loginService.deleteLocalStorage();

                    // Reload the app
                    location.reload();
                }
                if(err.error){
                    return throwError(err.error.message)
                }else{
                    return throwError('Tenemos un error, porfavor comunicarse con el desarrollador')
                }
            })
        );
    }
}
