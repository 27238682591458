<p-toast></p-toast>
<div class="login-panel">
  <div class="p-grid p-nogutter p-justify-center p-align-center" style="height:100%">
    <div class="p-xl-3 p-lg-6 p-md-8 p-sm-10">
      <p-panel header="Iniciar sesión">
        <form [formGroup]="loginForm">
          <div class="p-grid p-justify-center p-align-center">
            <div class="p-xl-6 p-lg-6 p-md-8 p-sm-10">
              <!-- <img src="https://www.cma-cgm.com/Images/v3-1/logo/logo_color.svg" class="login-logo">-->
              <img src="http://www.sellosdeseguridad.net/wp-content/uploads/2020/04/cropped-LOGO_SECSEL-2020-web.png"
                width="150px" class="login-logo">
            </div>

            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                <input id="input-email" type="email" formControlName="email" size="20" pInputText placeholder="Correo">
              </div>
              <small id="username2-help" style="float: left; padding-left: 10px;"
                class="p-error">{{getError('email')}}</small>
            </div>
            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                <input id="input-password" type="password" formControlName="password" pInputText
                  placeholder="Contraseña">
              </div>
              <small id="username2-help" style="float: left; padding-left: 10px;"
                class="p-error">{{getError('password')}}</small>
            </div>
          </div>
          <div class="p-grid p-jc-end p-mt-1">
            <div>
              <button pButton type="button" label="¿Olvidaste tu contraseña?" (click)="resetPassDialog = true"
                class="p-button-text"></button>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-12" style="text-align: right;">
              <small>Versión: {{ versionApp }}</small>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-6">
              <button pButton [disabled]="submitted" type="button"
                [label]="submitted ? 'Iniciando sesión ...' : 'Iniciar sesion'" class="ui-button-raised"
                (click)="onSubmit()"></button>
            </div>
          </div>
        </form>
      </p-panel>
    </div>
  </div>
</div>

<p-dialog header="Restablecer contraseña" [(visible)]="resetPassDialog" [style]="{width: '50vw'}">
  <div>
    <form [formGroup]="forgotPasswordForm">
      <h5>Email</h5>
      <input type="email" [style]="{'width':'100%'}" pInputText formControlName="email" />
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-grid p-jc-end">
      <p-button type="button" label="Restablecer contraseña" [loading]="isStarRecovery" (click)="sendResetLink()">
      </p-button>
    </div>
  </ng-template>
</p-dialog>
