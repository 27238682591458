<p-dialog [header]="labelSave" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="true" [resizable]="false" (onHide)="cancel()">
    <div class="modal-content">
        <div class="p-fluid p-grid">
            <div class="p-md-6">
                <label>Sede recepción</label>

                <p-dropdown [options]="sedes" [(ngModel)]="selectedSede" optionLabel="descripcion"
                    (onChange)="changeSede()">
                </p-dropdown>
            </div>

            <div class="p-md-6" *ngIf="selectedSede.usuarios">
                <label>Recibe</label>
                <p-dropdown [options]="selectedSede.usuarios"   [(ngModel)]="usuario" optionLabel="name"></p-dropdown>
            </div>
            <div class="p-md-6">
                <label >Guia:</label>
                <input pInputText type="text"  [(ngModel)]="guia">
            </div>
            <div class="p-md-6">
                <label>Transportadora:</label>
                <input pInputText type="text"  [(ngModel)]="transportadora">
            </div>


            <div class="p-md-3">

                <p-checkbox [(ngModel)]="confirmar" [binary]="true" label="Confirmar recepcion?"></p-checkbox>

            </div>
            <div class="p-md-3">
                <p-checkbox [(ngModel)]="exclusivo" [binary]="true" label="Inventario Exclusivo?"></p-checkbox>
                <!-- <p-button *ngIf="seleccionados.length" label="Enviar" (click)="enviar()" [badge]="seleccionados.length">
            </p-button> -->
            </div>

        </div>

        <div class="p-fluid p-grid p-mt-3">
            <ng-container *ngFor="let item of kits" >
                <div class="p-md-2" *ngIf="!item.disabled">
                    <button pButton pRipple type="button" [label]="item.serial"
                        class="p-button-success p-button-outlined" (click)="retirar(item)"></button>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="cancel()" label="Cancelar" styleClass="p-button-text p-button-danger">
        </p-button>
        <p-button icon="pi pi-check" (click)="despachar()" [label]="labelSave" styleClass="p-button-text">
        </p-button>
    </ng-template>
</p-dialog>