<p-menubar [model]="items">
    <ng-template pTemplate="start">
        <!--<img src="https://www.cma-cgm.com/Images/v3-1/logo/logo_color.svg" height="40" class="p-mr-2">-->

        <img src="https://produccion.sellosdeseguridad.net/publico/img/logo.png" height="40" class="p-mr-2">


    </ng-template>
    <ng-template pTemplate="end">
        
      <!--   <p-dropdown [options]="drows"  (change)="drows.items.value"  [group]="true">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span >{{group.label}}</span>
                </div>
            </ng-template>
        </p-dropdown> -->

            <p-chip [label]="user.name" icon="pi pi-user" [style]="{backgroundColor:'var(--bluegray-200)'}" styleClass="p-mr-2"></p-chip>
                    
            <button pButton *ngIf="!sesionLogout" label="Cambiar clave"
            icon="pi pi-lock" class="p-button-secondary p-mr-2" (click)="cambiarClave()"></button>

            <button pButton [label]="sesionLogout ? 'Cerrando sesión...' : 'Logout'" [disabled]="sesionLogout"
            icon="pi pi-power-off" (click)="logout()"></button>
     
    </ng-template>
    <!-- <p-avatar image="./assets/monumento.png" shape="circle" styleClass="p-mr-2" ></p-avatar>
    <ng-template pTemplate="start">
        <img src="./assets/monumento.png" height="40" class="p-mr-2">
    </ng-template> -->
    <!-- <ng-template pTemplate="end">
        <input type="text" pInputText placeholder="Buscar">
    </ng-template> -->
</p-menubar>
