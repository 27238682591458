import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RemisionesService } from 'src/app/remisiones/services/remisiones.service';
import { SedesService } from 'src/app/sedes/services/sedes.service';

@Component({
  selector: 'app-opciones-envio',
  templateUrl: './opciones-envio.component.html',
  styleUrls: ['./opciones-envio.component.css']
})
export class OpcionesEnvioComponent implements OnInit {
  sedes:any[]=[];
  selectedSede:any={};
  usuario:any;
  guia:string;
  confirmar:boolean=true;
  exclusivo:boolean=false;
  transportadora:string='';

  constructor(
    private remisionesService:RemisionesService,
    private sedesService: SedesService,
  ) { }
  @Input() kits:any[]=[];
  @Input() labelSave:string="Enviar";
  @Input() displayModal:boolean=false;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<string>();

  ngOnInit(): void {
    this.sedesService.getAll({usuarios:1}).subscribe((response) => {
      this.sedes = response.filter(sede => sede.id != 1);
      console.log(response[0])
      this.selectedSede = response[1];
      this.changeSede()
      if (this.kits){
        this.guia=this.kits[0].serial+" - "+this.kits[this.kits.length-1].serial
      }
    });

  }
  changeSede(){
    this.usuario=this.selectedSede.usuarios[0];
  }
  retirar(item){
    item.disabled=true;
  }

  cancel(){
    this.onCancel.emit();
  }
  despachar(){

    var json:any={
      verificados_seriales:this.kits.filter(item => item.disabled != true),
      usuario_recibe: this.usuario,
      exclusiva:this.exclusivo,
      confirmar:this.confirmar,
      guia:this.guia,
      transportadora:this.transportadora,
      sede: this.selectedSede
    };
    this.remisionesService.despachar({remision:json}).subscribe((resp) => {
      this.onSave.emit(resp.message);
    });

  }

}
