import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoginData, PasswordData, User } from '../interfaces/login.interfaces';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {


  private urlBase = environment.baseUrl;
  public user: User;
  public token: string;
  constructor(private _http: HttpClient, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
  }

  /**
   * Metodo para el login
   * @param {body}
   * @returns {Observable<any>}
   */
  public login(body: any): Observable<any> {
    return this._http.post(this.urlBase + '/login', body).pipe((map((resp: LoginData) => {

      if (resp['user'] && resp['access_token']) {
        this.setVariablesAndLocalStorage(resp);
      } else {
        return throwError(resp['message']);
      }
      return true
    })))
  }
  public getUser() {
    return this.user;
  }

  /**
   * Metodo que maneja que setea en el storage la data
   * @param {LoginData} loginData
   * @returns {void}
   */
  setVariablesAndLocalStorage(loginData: LoginData): void {
    localStorage.setItem('access_token', JSON.stringify(loginData['access_token']));
    localStorage.setItem('user', JSON.stringify(loginData['user']));
    localStorage.setItem('menu', JSON.stringify(loginData['menu']));
    this.user = loginData['user'];
    this.token = loginData.access_token;
  }

  /**
   * Metodo quue elimina los datos de la sesion
   * @returns {void}
   */
  deleteLocalStorage(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.user = null;
    this.token = null;
    window.location.replace('/');
  }

  /**
   * Metodo para cerrar la sesión
   * @returns {void}
   */
  public logout(): void {
    this._http.post(this.urlBase + '/logout', {}, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      })
    }).toPromise().then((resp) => {
      this.deleteLocalStorage();
    }).catch(err => {
      return throwError('Tenemos un error, porfavor comunicarse con el desarrollador')
    })
  }

  updatePassword(passwords: PasswordData): Observable<any> {
    return this._http.post(this.urlBase + '/cambiar-clave', passwords, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`
      })
    });

  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string) {
    return this._http.post(this.urlBase + '/forgot-password', email);
  }
}
