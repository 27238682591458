<p-table [columns]="table.columns"
	[value]="data"
	[rows]="table.rows"
	[paginator]="table.paginator"
	responsiveLayout="scroll">
	<ng-template pTemplate="header"
		let-columns>
		<tr>
			<th *ngFor="let col of columns">
				{{col.header}}
			</th>
			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body"
		let-rowData
		let-columns="columns">
		<tr>
			<td *ngFor="let col of columns"
				style="word-wrap: break-word;">
				<img [src]="rowData[col.image]" *ngIf="col.image" class="p-mr-2" style="max-width: 20px;max-height:20px;">
				{{rowData[col.field]}}
			</td>
			<td>
				<span *ngFor="let button of table.buttons">
					<button pButton
						pRipple
						type="button"
						[icon]="button.icon"
						[pTooltip]="button.title"
						tooltipPosition="top"
						[class]="button.class"
						(click)="buttonClick(button,rowData)"></button>
				</span>
			</td>
		</tr>
	</ng-template>
</p-table>