import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoginService } from '../../services/login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  forgotPasswordForm: FormGroup

  public submitted = false;
  versionApp = environment.versionApp
  resetPassDialog = false
  emailRecovery = ''
  isStarRecovery = false
  constructor(private messageService: MessageService,
    private _loginService: LoginService,
    private router: Router,
    private _formBuilder: FormBuilder) {
    this.initForms()
  }

  ngOnInit() { }

  /**
   * Metodo de login
   */
  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid) {
      this.messageService.add({ severity: 'info', summary: 'Información', detail: 'Debes llenar todos los campos' });
    }

    this._loginService.login(this.loginForm.value).subscribe(() => {
      this.router.navigate(['/']);
    }, err => {
      this.submitted = false
      this.messageService.add({ severity: 'error', summary: 'Información', detail: err });
    })
  }

  /**
   * Funcion que inicializa todos los formularios del componente
   * @returns {void}
   */
  private initForms(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('sucorreo@sellosdeseguridad.net', [Validators.required, Validators.email]),
      password: new FormControl('1234', Validators.required)
    });

    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  /**
     * Send the reset link
     */
   sendResetLink(): void {
    // Return if the form is invalid
    if (this.forgotPasswordForm.invalid) {
        return;
    }
    this.isStarRecovery = true

    // Forgot password
    this._loginService.forgotPassword(this.forgotPasswordForm.value).toPromise().then(() => {
      this.messageService.add({ severity: 'success', summary: 'Enhorabuena', detail: '¡Se envió el restablecimiento de contraseña! Recibirás un correo electrónico si estás registrado en nuestro sistema.' });
    }).catch(err => {
      this.messageService.add({ severity: 'info', summary: 'Atención', detail: '¡No encuentramos tu email!' });
    }).finally(() => {
      this.isStarRecovery = false
      this.forgotPasswordForm.reset()
    })
}


  /**
   * Funcion que valida los errores del formulario
   * @param {string} controlName
   * @returns {void}
   */
  public getError(controlName: string): string {
    let error = "";
    const control = this.loginForm.get(controlName);
    if (control.errors != null) {
      if (control.touched && control.errors.email) {
        error = "Ingrese un email correcto";
      }
      if (control.touched && control.errors.required) {
        error = "Este campo es requerido";
      }
    }
    return error;
  }
}
