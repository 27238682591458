import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './auth/guards/login.guard';
import { ErrorComponent } from './shared/pages/error/error.component';
import { LayoutComponent } from './shared/pages/layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule)
      },
      {
        path: 'produccion',
        loadChildren: () => import('./produccion/produccion.module').then(m => m.ProduccionModule)
      },
      {
        path: 'remisiones',
        loadChildren: () => import('./remisiones/remisiones.module').then(m => m.RemisionesModule)
      },
      {
        path: 'contenedores',
        loadChildren: () => import('./contenedores/contenedores.module').then(m => m.ContenedoresModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./stock/stock.module').then(m => m.StockModule)
      },
      {
        path: 'informes',
        loadChildren: () => import('./informe/informe.module').then(m => m.MasterModule)
      },
      {
        path: 'operaciones',
        loadChildren: () => import('./operaciones/operaciones.module').then(m => m.OperacionesModule)
      },
      {
        path: 'configuracion',
        loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      /* {
        path:'contactos',
        loadChildren:()=>import('./contactos/contactos.module').then(m=>m.ContactosModule)
      }, */
      {
        path: '404',
        component: ErrorComponent
      },
      {
        path: '**',
        redirectTo: '/inicio', pathMatch: 'full'
      }
    ]
  }
]

@NgModule({

  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
