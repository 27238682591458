import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @Input() table:any={};
  @Input() data:any=[];
  @Output() clickButton:any = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  buttonClick(button:any,item:any){
    this.clickButton.emit({button:button,item:item});
  }
}
