import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/auth/interfaces/login.interfaces';
import { LoginService } from 'src/app/auth/services/login.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styles: [
  ]
})
export class MenuComponent implements OnInit {
  items: MenuItem[];
  user: User;
  constructor(
    private loginService: LoginService,
    private _router: Router
  ) { }
  drows: any[] = [];
 // itemss: any[] = [];
  item: string;
  sesionLogout = false

  ngOnInit(): void {

    this.items = JSON.parse(localStorage.getItem('menu'));
    this.user = this.loginService.getUser();


    /*this.items = [
      {
        label: 'Inicio',
        icon: 'pi pi-home',
        routerLink: '/'
      },

      {
        label: 'Producción',
        icon: 'pi pi-user',
        items: [
          {
            label: 'Pedidos',
            routerLink: 'produccion/pedidos',
            icon: 'pi pi-users'
          },
          {
            label: 'Lecturas y Empaques',
            icon: 'pi pi-user-plus',
            routerLink: 'produccion/despachos'
          }
        ]
      },
      {
        label: 'Remisiones',
        icon: 'pi pi-send',
        items: [
          {
            label: 'Remisiones',
            routerLink: 'remisiones/index',
            icon: 'pi pi-book'
          },
          {
            label: 'Inventario',
            icon: 'pi pi-inbox',
            routerLink: 'remisiones/inventario'
          },
        ]
      },
      {
        label: 'Contenedores',
        icon: 'pi pi-inbox',
        items: [
          {
            label: 'Lecturas',
            icon: 'pi pi-tablet',
            routerLink: 'contenedores/leer'
          },
        ]
      },
      {
        label: 'Maestros',
        icon: 'pi pi-cog',
        items: [
          {
            label: 'Productos',
            icon: 'pi pi-plus-circle',
            routerLink: 'produccion/productos'
          },
          {
            label: 'Kits',
            icon: 'pi pi-plus-circle',
            routerLink: 'produccion/kits'
          }
          ,
          {
            label: 'Usuarios',
            icon: 'pi pi-users',
            routerLink: 'produccion/usuarios'
          },
        ]
      },
      {
        label: 'My Usuario',
        icon: 'pi pi-user',
        routerLink: '/auth'
        //Dashboard con stock inventario por sedes y productos o kits
      }

    ];*/
/*  
    this.drows = [
      {
        name: this.user.name
      },
      {
        name: 'Cambiar Clave'
      },
      {
        name: 'Lgout'
      }
    ]; */

    this.drows = [
      {
          label: this.user.name,value:"",
          items: [
          ], 
          
      },
      {
        label: 'Cambiar Clave',value:"", 
        items: [
        ]
      },
      {
        label: 'Logout',value:"", 
        items: [
        ]
      },
   ]; 
  }


  /**
   * Metodo para cerrar la sesión
   * @returns {void}
   */
  logout(): void {
    this.sesionLogout = true;
    this.loginService.logout();
  }

  cambiarClave() {
    this._router.navigate(["/configuracion/cambiar-clave"]);
  }

}
