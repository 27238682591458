import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/auth/interfaces/login.interfaces';

@Injectable({
  providedIn: 'root'
})
export class RemisionesService {
  baseUrl: string = environment.baseUrl;
  public user: User;
  public token: string;
  constructor(private http: HttpClient) { 
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
  }

  getAll(): Observable<any> {
    return this.http.get(this.baseUrl + '/remisiones/listar',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
    
  }
  getSedesUsers(): Observable<any> {
    return this.http.get(this.baseUrl + '/sedes/usuarios',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  recibir(id:number): Observable<any> {
    return this.http.get(this.baseUrl + '/remisiones/recibir/'+id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  
  despachar(datos:any): Observable<any> {
    return this.http.post(this.baseUrl + '/remisiones/despachar', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  pdfremision(id): Observable<any> {
    return this.http.get(this.baseUrl + '/remisiones/pdf/'+ id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  } 

}