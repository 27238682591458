import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../interfaces/login.interfaces';
import { LoginService } from '../services/login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(public router: Router, private _loginService: LoginService) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Guard for user is login or not

        let user: User = this._loginService.user || JSON.parse(localStorage.getItem('user'));

        if (!user || user === null || user === undefined) {
            this.router.navigate(['/auth/login']);
            return true
        }
        else if (user) {
            if (!Object.keys(user).length) {
                this.router.navigate(['/auth/login']);
                return true
            }
        }
        return true
    }

}
